
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customer-financial-statement-sme-cost-of-sales",
  components: {
    Form,
    ElCurrencyInputNoSymbol,
  },

  data() {
    const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const totalMonths = 0;

    const statements = {
      costSales: {
        label: "Cost of Sales",
        total: 0,
        child: {
          openingInventory: {
            label: "Opening Inventory",
            value: [],
            total: 0,
          },
          materialPurchases: {
            label: "Material Purchases",
            value: [],
            total: 0,
          },
          others: {
            label: "Others",
            value: [],
            total: 0,
          },
        },
      },
      totalCostSales: {
        label: "Total",
        value: [],
        total: 0,
      },
    };

    const closingInventory = {
      label: "Closing Inventory",
      value: [],
      total: 0,
    };

    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
    };

    return {
      router: useRouter(),
      isLoading,
      months,
      totalMonths,
      statements,
      closingInventory,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },

  async mounted() {
    setCurrentPageBreadcrumbs("Cost of Sales", [
      "Customer",
      "Financial Statements SME",
      "Income Statements",
    ]);
    this.init();
  },

  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
            this.closingInventory = this.store.getters.getFinancialStatementData.raw.closingInventory;
          });
      }
      this.initMonths();
    },

    initMonths() {
      for (let index = 0; index < this.months.length; index++) {
        this.statements.totalCostSales.value.push(0);
        this.closingInventory.value.push(0);
      }

      for (let key of Object.keys(this.statements.costSales.child)) {
        let costSalesChild = this.statements.costSales.child;
        for (let index = 0; index < this.months.length; index++) {
          costSalesChild[key].value.push(0);
        }
      }
    },

    calculateTotalCostSales() {
      for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
        this.statements.totalCostSales.value[i] = 0;
      }
      let result = 0;
      for (let keyChild of Object.keys(this.statements.costSales.child)) {
        let costSalesChild = this.statements.costSales.child[keyChild];
        let stateChildValue = costSalesChild.value;
        costSalesChild.total = stateChildValue.reduce(
          (sum, item) => sum + item
        );

        for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
          this.statements.totalCostSales.value[i] += stateChildValue[i];
        }

        result += costSalesChild.total;
      }

      this.closingInventory.total = this.closingInventory.value.reduce(
        (sum, item) => sum + item
      );

      this.statements.costSales.total = result - this.closingInventory.total;

      console.log(result - this.closingInventory.total);

      for (let i = 0; i < this.statements.totalCostSales.value.length; i++) {
        this.statements.totalCostSales.value[i] -= this.closingInventory.value[
          i
        ];
      }
    },

    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        financial_statement_type_id: 7,
        raw: {
          statements: this.statements,
          closingInventory: this.closingInventory,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("updated");
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            this.swalMessage("saved");
          });
      }
    },
    swalMessage(m) {
      Swal.fire({
        title: "Congratulation!",
        text: "Cost of Sales Statement has been " + m + "!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        this.router.push({
          path: `/customers/details/${this.cstmr_id}/financial-statements/sme/list`,
        });
      });
    },
  },
});
